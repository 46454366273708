









































import SKHeader from '@/components/SK-Header.vue';
import SKHero from '@/components/SK-Hero.vue';
import SKPosition from '@/components/SK-Position.vue';
import axios from '@/utils/axios';
import { getText } from '@/utils/langs';
import { Position, PositionSearchOptionsDTO } from '@/utils/models';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    SKHero,
    SKPosition,
    SKHeader
  }
})
export default class SearchResults extends Vue {
  public results: Position[] | null = null;

  async mounted() {
    this.results = null;
    const { data } = await axios.post(`position/search/${this.sex}`, {
      type: 'muscle',
      input: this.muscle
    } as PositionSearchOptionsDTO);

    this.results = data;
  }

  get sex(): string {
    const sex = this.$route.params.sex;
    if (sex.toLowerCase() === 'men') return sex.toLowerCase();
    return 'women';
  }

  get muscle(): string {
    return this.$route.params.muscle;
  }

  get title(): string {
    return getText('ui.search.results-for', 'Results for') + ' ' + this.muscle;
  }
}
